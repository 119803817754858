<template>
    <v-container fluid class="pt-0 mt-0">
        <HeaderAzul :titulo="conteudo.curso" :subtitulo="conteudo.turma"/>
        <v-row class="justify-center mt-0">
            <v-col cols="12">
                <v-card elevation="0" color="#00436F">
                    <v-card-title style="color: #FFFFFF; font-size: 25px;" class="pb-0 pt-6">
                        {{conteudo.conteudo}}
                        <v-spacer/>
                        <v-btn @click="dialogExibir({name : 'livroDialog', id_curso : parseInt(conteudo.id_curso), id_conteudo : parseInt(conteudo.id_conteudo)})" icon><v-icon color="#FFF">mdi-book-open-page-variant</v-icon></v-btn>
                    </v-card-title>
                </v-card>
            </v-col>
            <template v-for="(cf, i) in conteudo.conteudo_frase">
                <v-col v-if="cf.sei == 'f'" cols="12" md="6" lg="4" xl="3" :key="i">
                    <v-card>
                        <v-card-text class="pt-3 my-0" v-html="`${i+1}. ${cf.frase}`" style="white-space: pre-line; color: #000;"/>
                        <v-divider/>
                        <v-card-text v-show="cf.resposta_exibir" v-html="cf.resposta" style="white-space: pre-line;"></v-card-text>
                        <v-divider v-if="cf.resposta_exibir"/>
                        <v-card-actions>
                            <v-btn v-if="cf.sei == 'f'" text @click="cf.sei = 't';sei(cf.id_frase)"><v-icon left>mdi-check</v-icon> Eu sei</v-btn>
                            <v-spacer/>
                            <v-btn text @click="cf.sei = cf.resposta_exibir && cf.sei == 't' ? 't' : 'f';cf.resposta_exibir = !cf.resposta_exibir;naoSei(cf.id_frase, cf.resposta_exibir)"><v-icon left>mdi-eye</v-icon> {{!cf.resposta_exibir ? 'Traduzir' : 'Ocultar'}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </template>
            <template v-for="(cf, i) in conteudo.conteudo_frase">
                <v-col v-if="cf.sei == 't'" cols="12" md="6" lg="4" xl="3" :key="'s_'+i">
                    <v-card color="green" dark>
                        <v-card-text class="pt-3 my-0" v-html="`${i+1}. ${cf.frase}`" style="white-space: pre-line; color: #FFF;"/>
                        <v-divider style="border-color: #FFFFFF33"/>
                        <v-card-actions>
                            <v-btn v-if="cf.sei == 'f'" text @click="cf.sei = 't';sei(cf.id_frase)"><v-icon left>mdi-check</v-icon> Eu sei</v-btn>
                            <v-spacer/>
                            <v-btn text @click="cf.sei = cf.resposta_exibir && cf.sei == 't' ? 't' : 'f';cf.resposta_exibir = !cf.resposta_exibir;naoSei(cf.id_frase, cf.resposta_exibir)"><v-icon left>mdi-eye</v-icon> {{!cf.resposta_exibir ? 'Traduzir' : 'Ocultar'}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>

import axios from "axios"
import {mapMutations, mapState} from "vuex"
import HeaderAzul from "@/Views/Estrutura/HeaderAzul"

export default {
    name: "CursoAulaAtual",
    props : ['id_aula', 'id_conteudo'],
    components: {HeaderAzul},
    computed : {
        ...mapState(['baseUrl'])
    },
    data: () => ({
        conteudo : {},
    }),
    methods : {
        ...mapMutations(['dialogExibir']),
        getCurso() {
            return axios.post(`${this.baseUrl}aula/conteudo_get_app`, {id_conteudo : this.id_conteudo, id_aula : this.id_aula}).then( (res) => {
                let dados = res.data
                dados.conteudo_frase = dados.conteudo_frase.map((v) => {return {...v, resposta_exibir : false}})
                this.conteudo = dados
            });
        },
        init() {
            this.getCurso()
        },
        async sei(id_frase) {
            await axios.post(`${this.baseUrl}aula/conteudo_frase_sei`, {id_aula : this.id_aula, id_turma : this.conteudo.id_turma, id_curso : this.conteudo.id_curso, id_conteudo : this.id_conteudo, id_frase, id_level : this.conteudo.id_level})
        },
        async naoSei(id_frase, exibir) {
            if (exibir) {
                await axios.post(`${this.baseUrl}aula/conteudo_frase_nao_sei`, {id_aula : this.id_aula, id_turma : this.conteudo.id_turma, id_curso : this.conteudo.id_curso, id_conteudo : this.id_conteudo, id_frase, id_level : this.conteudo.id_level})
            }
        },
    },
    activated() {
        this.init()
    },
}
</script>

<style scoped>

</style>